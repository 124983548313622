import React from 'react';
import QuoteLayout from '../../components/QuoteLayout/quote-layout';
import QuoteContext from '../../context/quote-context';
import DietaryRestrictions from '../../components/GetQuoteSteps/dietary-restrictions';

export default function QuoteDietaryRestrictionsPage() {
  return (
    <QuoteLayout>
      <QuoteContext.Consumer>
        {quote => (
          <DietaryRestrictions set={quote.set} dietaryRestrictions={quote.dietaryRestrictions} />
        )}
      </QuoteContext.Consumer>
    </QuoteLayout>
  );
}
