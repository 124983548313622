import React from 'react';
import { useIntl } from 'react-intl';
import { LocalizedLink as Link } from 'gatsby-theme-i18n';
import FormContainer from '../Form/form-container';
import FormHeadline from '../FormHeadline/form-headline';
import CheckBox from '../Form/checkbox';

export default function DietaryRestrictions({ set, dietaryRestrictions }) {
  const intl = useIntl();
  return(
    <FormContainer>
      <FormHeadline>{intl.formatMessage({ id: "quoteDietaryRestrictionsHeadline" })}</FormHeadline>

      <Link to={'/get-quote/dietary-restrictions-specifics'}>
        <CheckBox checked={dietaryRestrictions === 'yes'} handleClick={() => set({dietaryRestrictions: 'yes'})}>
          <div>{intl.formatMessage({ id: "quoteYes" })}</div>
        </CheckBox>
      </Link>

      <Link to={'/get-quote/contact-info'}>
        <CheckBox checked={dietaryRestrictions === 'no'} handleClick={() => set({dietaryRestrictions: 'no'})}>
          <div>{intl.formatMessage({ id: "quoteNo" })}</div>
        </CheckBox>
      </Link>
    </FormContainer>
  );
}
